<template>
    <div :class="`p-2 ${$isMobile() ? '' : 'container-lg'}`" v-if="shelter">

        <!-- header -->
        <div class="p-1" style="border-radius: 10px;border:1px solid #FFAD32">
            <div class="d-flex">
                <img style="height:150px !important;width:150px !important;object-fit:cover" class="rounded-circle m-auto" 
                :src="shelter.shelter_picture" />
            </div>
        
            <div class="text-white text-center my-1 px-2">
                <div class="d-flex align-items-center justify-content-center" style="gap:10px">
                    <h2 class="text-white font-weight-bolder mb-0">{{ shelter.shelter_name }}</h2>
                    <router-link :to="`/chats/${shelter.user_id}`" v-if="user.user_id != shelter.user_id">
                        <div class="text-white d-flex align-items-center" style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                            <i class="bx bxs-message-dots" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                        </div>
                    </router-link>
                    <router-link :to="`/shelter-register`" v-else>
                        <div class="text-white d-flex align-items-center" style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                            <i class="bx bxs-edit" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                        </div>
                    </router-link>
                </div>
                <p class="mt-1">
                    {{ shelter.shelter_details }}
                </p>

                <h3 class="font-weight-bold text-white">{{shelter.shelter_location}}</h3>
                <h3 class="font-weight-bold text-white mb-0">{{ shelter.shelter_phone }}</h3>
            </div>
            
            <div class="d-flex">
                <div class="badge bg-danger m-auto" v-if="shelter.is_approved == 'n'">
                    Waiting for approval
                </div>
            </div>
        </div>
        <!-- end header -->

        <!-- services -->
        <div class="my-3">
            <h3 class="font-weight-bold text-white">Choose Service</h3>
            <div class="d-flex align-items-center justify-content-center flex-wrap px-2 " style="gap:10px 30px">
                <router-link :to="`/shelter/volunteer/${id}`" class="d-flex flex-column align-items-center">
                    <div class="rounded-circle d-flex" style="width: 60px;height:60px;">
                        <img src="@/static/img_assets/Handshake.png" class="w-100 m-auto"   />
                    </div>
                    <div class="text-center font-weight-bolder h4 text-white" style="margin-top:.5em">Volunteer</div>
                </router-link> 
                <router-link :to="`/shelter/donate/${id}`" class="d-flex flex-column align-items-center">
                    <div class="rounded-circle d-flex" style="width: 60px;height:60px;">
                        <img src="@/static/img_assets/Money.png" class="w-100 m-auto"   />
                    </div>
                    <div class="text-center font-weight-bolder h4 text-white" style="margin-top:.5em">Donate</div>
                </router-link> 
                <router-link :to="`/shelter/adopt/${id}`" class="d-flex flex-column align-items-center">
                    <div class="rounded-circle d-flex" style="width: 60px;height:60px;">
                        <img src="@/static/img_assets/Dog Paw Print.png" class="w-100 m-auto"   />
                    </div>
                    <div class="text-center font-weight-bolder h4 text-white" style="margin-top:.5em">Adopt</div>
                </router-link> 
            </div>
        </div>
        <!-- end services -->

        <div class="text-white mt-5">
            <h2 class="text-white font-weight-bolder">Our Gallery</h2>

            <div class="d-flex flex-wrap align-items-center  mt-1" style="gap:10px">
                <div v-if="user.user_id == shelter.user_id" @click="selectPicture()" class="d-flex" style="background-color: #D9D9D9;border-radius: 10px;height: 100px;width: 100px;object-fit: cover;">
                    <i class="bx bx-plus m-auto" style="font-size: 42px;color: rgb(80, 80, 80);"></i>
                </div>
                <div v-for="item in gallery" class="position-relative">
                    <i v-if="user.user_id == shelter.user_id" @click="deleteGallery(item.shg_id)" class="bx bxs-trash text-danger position-absolute" style="font-size: 20px;right:10px;bottom:10px"></i>
                    <img @click="changeImage(item)" :src="item.shg_picture" class="" style="background-color: #D9D9D9;border-radius: 10px;height: 100px;width: 100px;object-fit: cover;"/>
                </div>
            </div>

            <!-- <div class="row my-2" style="gap:20px 0">
                <div class="col-12">
                    <img style="border-radius: 10px;" class="w-100" src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-cover-template-design-7fbcea6de5ed45af0716f61106314548_screen.jpg?ts=1622085870" />
                </div>
                <div class="col-6">
                    <img style="border-radius: 10px;" class="w-100" src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-cover-template-design-7fbcea6de5ed45af0716f61106314548_screen.jpg?ts=1622085870" />
                </div>
                <div class="col-6">
                    <img style="border-radius: 10px;" class="w-100" src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-cover-template-design-7fbcea6de5ed45af0716f61106314548_screen.jpg?ts=1622085870" />
                </div>
            </div> -->

            <!-- <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vehicula porttitor magna a porttitor. Fusce nibh lectus, faucibus ut imperdiet nec,
            </p> -->
        </div>

        <form id="form" v-if="user.user_id == shelter.user_id">
            <input type="file" name="shg_picture" id="shg_picture" class="d-none" accept="image/*" @change="onChange(e)" />
        </form>

        <image-viewer-vue 
            v-if="imageViewerFlag" 
            @closeImageViewer="imageViewerFlag = false" 
            :imgUrlList="imgUrlList"
            :index="currentIndex"
            :title="title"
            :closable=true
            :cyclical=false>
        </image-viewer-vue>

    </div>
</template>

<script>

import store from '@/store'

export default {
    computed: {
        user(){
            return store.state.auth.USER
        },
        shelter(){
            return store.state.shelter.SHELTER
        },
        gallery(){
            return this.shelter?.gallery
        }
    },
    methods: {
        changeImage(data,index){ 
            this.imageViewerFlag = true
            this.imgUrlList = [data.shg_picture]
        },
        deleteGallery(id){
            store.dispatch('shelter/DeleteGallery', id).then(() => {
                store.dispatch('shelter/GetShelter', this.id)
            })
        },
        onChange(e){
            let formData = new FormData($('#form')[0]) 
            formData.append('inp[shelter_id]', this.id)  
            store.dispatch('shelter/SaveGallery', formData).then(() => {
                store.dispatch('shelter/GetShelter', this.id)
            })
        },
        selectPicture(){
            $('#shg_picture').click()
        },
    },
    mounted(){
        store.dispatch('shelter/GetShelter', this.id)
    },
    data(){
        return {
            id: this.$route.params.id,

            imageViewerFlag: false,
            currentIndex: 0,
            title: 'Image Preview',
            imgUrlList: []
        }
    }
}

</script>